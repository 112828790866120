import axios from 'axios';
import { ArticleFeedbackDto } from '../../model/dto/articleFeedbackDto';
import { KnowledgeArticleDto } from '../../model/dto/knowledgeArticleDto';
import { KnowledgeArticlesDto } from '../../model/dto/knowledgeArticlesDto';


class ArticleApi {
  CreateArticleFeedback = async (dto: ArticleFeedbackDto): Promise<boolean> => {
    let url = `${process.env.REACT_APP_API_URL}/Articles`;

    return axios
      .post(url, dto)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  GetKnowledgeArticles = async (itemsPerPage: number, pageNumber: number, searhTerm: string | null, category?:string, signal?: AbortSignal): Promise<KnowledgeArticlesDto> => {

    let url = `${process.env.REACT_APP_API_URL}/Articles?itemsPerPage=${itemsPerPage}&pageNumber=${pageNumber}`;

    if (searhTerm) {
      url += `&query=${searhTerm}`;
    }
    if (category && category != 'all') {
      url += `&category=${category}`;
    }

    return axios
      .get(url, { signal: signal })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };
  
  GetKnowledgeArticle = async (articleNumber: string | undefined, articleTitle: string | undefined, embeded?: boolean | undefined): Promise<KnowledgeArticleDto> => {
    let url = ''
    if(!!articleNumber) url = `${process.env.REACT_APP_API_URL}/Articles/${articleNumber}?`;
    else if(!!articleTitle) url = `${process.env.REACT_APP_API_URL}/Articles/ByTitle/${articleTitle}?`;
    
    
    if(!!embeded) url = `&embedded=${embeded}`;
    

    return axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  GetReleaseNotesArticleNumber = async (): Promise<string> => {
    let url = `${process.env.REACT_APP_API_URL}/Articles/ReleaseNotesArticleNumber`;

    return axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };
}

export default new ArticleApi;