import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {createRoot} from 'react-dom/client';
import App from './App';
import './index.css';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AppInsightsContext, ReactPlugin} from '@microsoft/applicationinsights-react-js';
import { ToastProvider } from '@laerdal/life-react-components';
import posthog from 'posthog-js';


var reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({ config: {
  connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS,
  enableAutoRouteTracking: true,
  extensions: [reactPlugin],
} });
appInsights.loadAppInsights();

const setPosthogByConsent = () => {  
  console.log('checkOneTrust')
  //@ts-ignore
  if(window.OnetrustActiveGroups?.indexOf('C0002') >= 0){
    console.log('C0002 - true')
    posthog.config.api_host = 'https://anltcs.laerdal.com';
    posthog.config.persistence = 'cookie';
    posthog.startSessionRecording();

    posthog.opt_in_capturing();
  }
  else {
    console.log('C0002 - false')
    posthog.config.api_host = 'https://hogproxy.laerdal.com/ingest';
    posthog.config.persistence = 'memory';
    posthog.stopSessionRecording();
    posthog.capture("Opt out");
  }
  console.log('checkOneTrust - done');
}

//@ts-ignore
posthog.init(process.env.REACT_APP_POSTHOG_DBU, {
  //@ts-ignore
  api_host: window.OnetrustActiveGroups?.indexOf('C0002') >= 0 ?
              'https://anltcs.laerdal.com'
              : 'https://hogproxy.laerdal.com/ingest',
  ui_host: 'https://eu.posthog.com'
});
setPosthogByConsent();
//@ts-ignore
if (!window.posthog) {
  //@ts-ignore
  window.posthog = posthog;
}
console.log('posthog.init')

setTimeout(()=>{
  console.log('setTimeout')

  //@ts-ignore
  if(window.OnetrustActiveGroups?.indexOf('C0002') >= 0 && posthog.config.api_host != 'https://anltcs.laerdal.com')
    posthog.reset();
  //@ts-ignore
  if(window.OnetrustActiveGroups?.indexOf('C0002') < 0 && posthog.config.api_host != 'hogproxy.laerdal.com/ingest')
    posthog.reset();
  setPosthogByConsent();

  //@ts-ignore
  window.OneTrust?.OnConsentChanged(() => { 
    console.log('OnConsentChanged')

    posthog.reset();
    console.log('posthog.reset')
    
    //@ts-ignore
    setPosthogByConsent();
  });
  
  if(window.self !== window.top){
    // @ts-ignore
    posthog?.capture('helpU opened',{
      $geoip_disable: true,
    })
  }
}, 1000);



const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ToastProvider>
     <AppInsightsContext.Provider value={reactPlugin}>
      <App />
     </AppInsightsContext.Provider>
    </ToastProvider>
  </BrowserRouter>
  );

