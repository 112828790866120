import axios from 'axios';
import { CaseCreateDto } from '../../model/dto/caseCreateDto';

class CaseApi {
  CreateCase = async (dto: CaseCreateDto): Promise<string> => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/Cases`, dto)
      .then((response) => {
        return response.data;
      });
  };
}

export default new CaseApi;