import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";

import styled from "styled-components";
import Topics from "../../commonComponents/Topics";
import Subtopics from "../../commonComponents/Subtopics";
import Faqs from "../../commonComponents/Faqs";
import ProductSupport from "./components/ProductSupport";
import KnowledgeHub from "./components/KnowledgeHub";
import LaerdalYoutube from "./components/LaerdalYoutube";
import LaerdalPrograms from "./components/LaerdalPrograms";
import LandingHeader from "./components/LandingHeader";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import GlobalWarenty from "./components/GlobalWarenty";


const Page = styled(PageWidth)`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  gap:32px
`;

const FullGrayPageContainer = styled.div`
  display:flex;
  background: #F5F5F5
`;



const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const PopularTopics = styled.div`
  display:flex;
  flex-direction:column;
  padding: 16px;
  gap: 16px;
  border-radius: 10px;
  background: ${COLORS.neutral_50};
`;
const PopularTopicsHeading = styled.div`
  color: #1D1D1F;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
  letter-spacing: 0.216px;
`;


const LandingPage = () => {

  return (
    <>
      <LandingHeader />

      <Page useMaxWidth={true} maxWidth={1024}>
        <PopularTopics>
          <PopularTopicsHeading>Popular topics</PopularTopicsHeading>
          <Topics
            items={[{
              text: "Baby/Newborn manikin",
              asset: "assets/baby-newborn-manikin.png",
              heightPx: "144px",
              link: "baby-newborn-manikin"
            }, {
              text: "Kid/Junior manikin",
              asset: "assets/kid-junior-manikin.png",
              heightPx: "144px",
              link: "kid-junior-manikin"
            }, {
              text: "Male manikin",
              asset: "assets/male-manikin.png",
              heightPx: "144px",
              link: "male-manikin"
            }, {
              text: "Female manikin",
              asset: "assets/female-manikin.png",
              heightPx: "144px",
              link: "female-manikin"
            }, {
              text: "Software and apps",
              asset: "assets/software.png",
              heightPx: "144px",
              link: "software"
            }, {
              text: "Training devices",
              asset: "assets/training-devices.png",
              heightPx: "144px",
              link: "training-devices"
            }, {
              text: "Medical devices",
              asset: "assets/medical-devices.png",
              heightPx: "144px",
              link: "medical-devices"
            }
            ]}
          />
        </PopularTopics>
        <Subtopics
          items={[{
            icon: <ContentIcons.ProvidersOrders size="48" color={COLORS.primary_500} />,
            text: "Order, Invoice and Shipment",
            link: "https://laerdal.com/support/ordering-shipping/"
          }, {
            icon: <ContentIcons.PhysiologicalModel size="48" color={COLORS.primary_500} />,
            text: "Spare parts Catalogue",
            link: "https://laerdal.com/support/catalog/"
          }, {
            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
            text: "How-to videos",
            link: "https://laerdal.com/support/how-to-videos/"
          }, {
            icon: <ContentIcons.Stock size="48" color={COLORS.primary_500} />,
            text: "Product returns",
            link: "https://laerdal.com/us/support/ordering-shipping/product-returns/"
          }
          ]}
        />
        <Faqs
          title="Popular articles"
          items={[{
            text: "How do I connect the SimPad Link Box to the simulator?",
            link: "/articles/000001045"
          }, {
            text: "How to access Order, Shipment, or Invoice Information on Laerdal Connect?",
            link: "/articles/000007252"
          }, {
            text: "How should I maintain the battery in SimPad PLUS?",
            link: "/articles/000004139"
          }, {
            text: "I've configured SimMan 3G in Client mode, but why can't I see it on the network?",
            link: "/articles/000006325"
          }, {
            text: "How often do I need to renew my TeamReporter license?",
            link: "/articles/000005978"
          }
          ]}
        />

        <PageContainer>
          <ProductSupport />
          <KnowledgeHub />
          <LaerdalYoutube />
          <GlobalWarenty/>
          <LaerdalPrograms />
          <CantFindCreateCase />
        </PageContainer>
      </Page>
    </>);
};

export default LandingPage;